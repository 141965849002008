import React from "react";
import Layout from "../components/organisms/layout/layout";
import CategoryDetailTemplate from "../components/templates/category-detail-template/categoryDetailTemplate";

const CustomProduct = (props) => {
  const { description, subcategories, textTitle, productsNews } = props.pageContext;

  return (
    <Layout title={`Dicar | Categoría - ${textTitle}`}>
      <CategoryDetailTemplate
        title={textTitle}
        description={description}
        products={subcategories}
        newsProducts={productsNews}
      />
    </Layout>
  )
}

export default CustomProduct;